@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
	font-family: 'Mulish', sans-serif;
}

:root {
	--maincolor: #33bba9;
}

body {
	font-family: 'Mulish', sans-serif;
	width: 100%;
	background: #f0dbbb;
}

.container {
	max-width: 1240px;
	width: 100%;
	margin: 0 auto;
	padding: 0 20px;
}

.nav {
	padding: 0px 60px;
	height: 64px;
	background-color: #fff;
	box-shadow: 0 0 5px grey;
	position: fixed;
	z-index: 50;
	align-items: center;
	background: white;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	top: 0;
	width: 100%;
	left: 0;
}

.toggle {
	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	margin-right: 20px;
}
.toggle i {
	color: #33bba9;
	font-size: 23px;
}

.nav .logo {
	color: #33bba9;
	text-decoration: none;
	font-size: 20px;
}

.toggle .fa-xmark {
	display: none;
}

.toggle.active .fa-bars {
	display: none;
}

.toggle.active .fa-xmark {
	display: initial;
}

.nav-list {
	display: flex;
	list-style: none;
	gap: 30px;
	color: #33bba9;
	font-size: 18px;
}

.nav-item a {
	display: flex;
	gap: 10px;
	cursor: pointer;
	color: #33bba9;
	text-decoration: none;
}

.sidebar {
	position: fixed;
	max-width: 300px;
	width: 100%;
	height: 100%;
	left: 0;
	top: 64px;
	background-color: #fff;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
	overflow-y: scroll;
	scrollbar-width: 0;
	-ms-overflow-style: none;
	transition: 0.3s ease-in-out;
	transform: translateX(-105%);
}

.sidebar.active {
	transform: translateX(0);
}

.sidebar::-webkit-scrollbar {
	display: none;
}

.sidebar-item {
	padding: 20px;
}

.sidebar-menu {
	display: flex;
	list-style: none;
	flex-direction: column;
	padding: 20px;
}

.sidebar-item a {
	color: #33bba9;
	text-decoration: none;
	font-size: 20px;
}

.main-content {
	margin: 50px 0;
}

.main-title {
	color: #33bba9;
	font-size: 56px;
	text-align: center;
	font-weight: 400;
	margin: 20px;
}

.main-content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.center-content {
	margin-bottom: 20px;
	text-align: center;
}

.center-content p {
	margin-bottom: 5px;
}

.main-btn {
	padding: 18px 54px;
	text-transform: uppercase;
	background-color: #33bba9;
	color: #fff;
	font-size: 16px;
	border: none;
	outline: none;
	cursor: pointer;
	text-decoration: none;
}

.head-img {
	display: block;
	max-width: 100%;
	margin: 0 auto;
}

.updates-cards {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 10px;
	row-gap: 40px;
	margin-top: 20px;
}

.updates_card img {
	width: 100%;
}

.updates {
	margin-top: 30px;
	margin-bottom: 30px;
}

.line {
	width: 50px;
	background-color: black;
	height: 1px;
	margin: 0 auto;
	margin-bottom: 20px;
}

.updates h2,
.contact-us h2,
.secondary-title {
	text-align: center;
	font-size: 18px;
	font-weight: 300;
	text-transform: uppercase;
}

.updates_card h4 {
	font-size: 18px;
	padding: 30px 0;
	font-weight: 500;
}

.updates_card-date {
	color: #33bba9;
	font-size: 16px;
	margin-bottom: 20px;
	display: block;
}

.updates_card p {
	font-weight: 500;
}

.updates_card .updates_card-content {
	margin-bottom: 30px;
	list-style: none;
	padding-left: 10px;
	font-weight: 400;
}

/* Contact Us */

.contact-us {
	margin: 50px 0;
	margin-top: 100px;
}

.contact-us a {
	display: inline-block;
}

.contact-cards {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 30px;
	margin-top: 40px;
}

.contact-cards h4 {
	font-size: 20px;
	margin-bottom: 30px;
}

.contact-cards .tel {
	margin-top: 50px;
	display: block;
}

.table {
	font-size: 16px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	list-style: none;
}

footer {
	height: 70px;
	background: #33bba9;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

footer .link {
	color: #ebebeb;
	text-decoration: none;
	transition: all 0.3s ease-in-out;
}

footer .link:hover {
	color: #fff;
	text-decoration: underline;
}

.privacy{
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	padding-top: 100px;
}

.privacy h1{
	text-align: center;
	margin-bottom: 20px;
}

@media screen and (max-width: 770px) {
	.updates-cards {
		grid-template-columns: repeat(2, 1fr);
	}
}
@media screen and (max-width: 700px) {
	.contact-cards {
		grid-template-columns: 1fr;
	}
	.main-title {
		font-size: 32px;
	}
}

@media screen and (max-width: 600px) {
	.updates-cards {
		grid-template-columns: 1fr;
		gap: 50px;
	}
}

@media screen and (max-width: 490px) {
	.nav {
		padding: 0 20px;
	}
	.phone {
		display: none;
	}
}
